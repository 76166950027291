<template>
<div class="Recharge page">
  <headers title="充值管理" right-text="充值" @clickRight="$router.push('/SubmitRecharge')"></headers>
  <div class="scroll">
    <ul class="list"  v-if="list.length > 0">
      <li v-for="(item,index) in list" :key="index">
        <div class="top flexSpaceBetween">
          <p class="bankname">{{item.marker}}</p>
          <p class="price">+ {{item.price}}</p>
        </div>
        <div class="bottom flexSpaceBetween">
          <p class="addtime">{{item.add_time}}</p>
          <p class="status">{{ getStateText(item.status)}}</p>
        </div>
      </li>
    </ul>

    <van-empty v-else description="暂无数据" />

  </div>
</div>
</template>

<script>
import headers from "@/components/headers.vue";
export default {
  name: "Recharge",
  components: {
    headers
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      $api.chongzhijilu({
          token: this.$store.getters['getToken']
      }).then(res => {
        this.list = res.data;
      })
    },
    getStateText(status){
      // 1.待处理，2.已驳回，3.已完成
      switch (Number(status)){
        case 1:
          return "待处理"
        case 2:
          return "已驳回"
        case 3:
          return "已完成"
      }
    }

  }
}
</script>

<style lang="scss">
.headers .right {
    color: $color;
}
.Recharge {
  .list {
    padding: 0 16px;
    li {
      padding: 15px 0;
      font-size: 13px;
      border-bottom: 1px solid #f0f0f0;
      .bottom {
        margin-top: 12px;
      }
      .bankname {
        font-size: 16px;
      }
      .price {
        font-size: 18px;
        font-weight: bold;
      }
      .addtime {
        color: #999;
      }
    }
  }
}
</style>